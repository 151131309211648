import React from 'react';
import './admin.css';
import Login from './Login/Login';
import AdminControls from './AdminControls/AdminControls';

import { Outlet } from 'react-router-dom';

//firebase
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../firebase_config';

// redux
import { useDispatch, connect } from 'react-redux';
import { userAuth } from '../../../redux/actions/userAuthActions';

const Admin = (props) => {
  const dispatch = useDispatch();

  onAuthStateChanged(auth, (currentUser) => {
    dispatch(userAuth(currentUser));
  });

  const logOutUser = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className='admin_wrapper'>
      <div className='admin_container'>
        <div className='admin_info'>
          <p>
            {props.RX_user
              ? 'Welcome back, ' + props.RX_user.email
              : 'Please log in'}
          </p>
          <button onClick={() => logOutUser()} className='admin_logout'>
            Log out
          </button>
        </div>
        <div className='admin_content'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    RX_user: state.setUser.user,
  };
}
export default connect(mapStateToProps)(Admin);
