import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './SideLink.module.css';

function SideLink({ title, to }) {
  return (
    <div className={Styles.SideLinkWrapper}>
      <Link to={to}>
        <div className={Styles.SideLink}>
          <p>{title}</p>
        </div>
      </Link>
    </div>
  );
}

export default SideLink;
