import React, { useEffect, useState } from 'react';
import './review.css';

import Review from './Review';

//Firestore stuff
import { collection, doc, getDocs } from 'firebase/firestore';
import { db } from '../../../../../firebase_config';

const booksAndTheirColl = {
  rover: 'rover_reviews',
  aditi: 'aditi_reviews',
  kalkin: 'kalkin_reviews',
  declined: 'declined_reviews',
  pending: 'unapproved_reviews',
};

const BookReview = ({ bookReviewData, book }) => {
  const [listUpdater, thowListUpdater] = useState(false);
  const [reviews, setReviews] = useState([]);
  const roverRef = collection(
    db,
    booksAndTheirColl[book] ? booksAndTheirColl[book] : 'rover_reviews'
  );

  const updateList = () => {
    thowListUpdater((x) => !x);
  };

  const fetchBookReviews = async () => {
    const querySnapshot = await getDocs(roverRef);
    setReviews([]);
    querySnapshot.forEach((doc) => {
      setReviews((curReview) => [...curReview, doc]);
    });
  };
  useEffect(() => {
    fetchBookReviews();
  }, [listUpdater]);

  // useEffect(() => {
  //   setReviews(reviews);
  // }, [bookReviewData]);
  return (
    <div className='BookReview'>
      <p>Please click on a review to expand</p>
      <br></br>
      <table>
        <thead>
          <tr>
            <th>Reviewer</th>
            <th>Rating</th>
            <th>Review</th>
            <th>Controls</th>
          </tr>
        </thead>
        <tbody>
          {reviews?.map((review) => {
            return (
              <Review
                key={Math.random()}
                updateThrow={updateList}
                reviewDoc={review}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BookReview;
