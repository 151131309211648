import React, { useState } from 'react';
import './review.css';

import Stars from '../../../../Stars/Stars';

//Firestore shizzle
import { db } from '../../../../../firebase_config';
import { doc, deleteDoc, addDoc, collection } from 'firebase/firestore';

const Review = ({ reviewDoc, updateThrow }) => {
  const bookColls = ['rover_reviews', 'aditi_reviews', 'kalkin_reviews'];

  let rev = reviewDoc.data();
  const docRef = reviewDoc.ref;
  const reviewerName = rev.reviewer;
  const reviewRating = rev.rating;
  const review = rev.review;

  const unapprovedRef = collection(db, 'declined_reviews');

  const handleUnapprove = async () => {
    if (
      window.confirm('Are you sure you want to unapprove this review ?') == true
    ) {
      try {
        await addDoc(unapprovedRef, { ...rev }).then(() => {
          deleteDoc(doc(db, docRef._key.collectionGroup, docRef.id));
        });
        updateThrow();
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const handleApprove = async () => {
    if (
      window.confirm('Are you sure you want to approve this review ?') == true
    ) {
      try {
        let collName = rev.book + '_reviews';
        const collRef = collection(db, collName);
        await addDoc(collRef, { ...rev }).then(() => {
          let documentRef = doc(db, docRef._key.collectionGroup, docRef.id);
          deleteDoc(documentRef);
        });
        updateThrow();
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const [expand, setExpand] = useState(false);
  return (
    <tr
      onClick={() => setExpand(!expand)}
      className={expand ? 'book_review expand' : 'book_review'}
    >
      <td>{reviewerName}</td>
      <td className='review_review_column'>{review}</td>
      <td className='review_star_column'>
        <Stars count={reviewRating} />
      </td>
      <td>
        {(bookColls.includes(docRef._key.collectionGroup) ||
          docRef._key.collectionGroup == 'unapproved_reviews') && (
          <button
            className='decline button'
            onClick={() => {
              handleUnapprove();
            }}
          >
            Decline
          </button>
        )}
        {(docRef._key.collectionGroup == 'declined_reviews' ||
          docRef._key.collectionGroup == 'unapproved_reviews') && (
          <button
            className='approve button'
            onClick={() => {
              handleApprove();
            }}
          >
            Approve
          </button>
        )}
      </td>
    </tr>
  );
};

export default Review;
