import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const Price = ({ productPrice }) => {
  const [price, setPrice] = useState(productPrice);

  useEffect(() => {
    console.log(price);
  });

  return <h2>S{productPrice}S</h2>;
};

export default Price;
