import React from 'react';
import Styles from './NotFound.module.css';

const NotFound = () => {
  return (
    <div className={Styles.NotFound}>
      <p style={{ fontSize: '5rem' }}>404</p>
      <p>Oops, Page Not Found</p>
    </div>
  );
};

export default NotFound;
