import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Dropdown = ({ title, children }) => {
  const [menuToggled, toggleMenu] = useState(false);

  const handleDropDownToggle = () => {
    toggleMenu((currMenu) => !currMenu);
  };
  const handleMouseLeave = () => {
    toggleMenu((currMenu) => (!currMenu ? currMenu : !currMenu));
  };

  return (
    <li
      onClick={handleDropDownToggle}
      onMouseLeave={handleMouseLeave}
      className={
        menuToggled ? 'item has-submenu submenu-active' : 'item has-submenu'
      }
    >
      <a>
        {title} <i className='icon-caret-down' />
      </a>
      <ul className='submenu'>{children}</ul>
    </li>
  );
};

const Option = ({ title, to, children, onClick }) => {
  if (!title && children) {
    return <li>{children}</li>;
  } else if (to && title) {
    return (
      <li>
        <Link onClick={onClick} to={to}>
          {title}
        </Link>
      </li>
    );
  } else {
    return <></>;
  }
};

Dropdown.Option = Option;

export default Dropdown;
