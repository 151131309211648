import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Redirect,
  Link,
} from 'react-router-dom';
import ReactGA from 'react-ga';

import Home from './components/Pages/Home/Home';
import Book from './components/Pages/Book/book';
import Header from './components/Header/Header';
import Overlay from './components/Overlay/Overlay';
import AboutRover from './components/Pages/AboutRover/AboutRover';
import Foot from './components/Foot/Foot';
import Admin from './components/Pages/Admin/Admin';
import Login from './components/Pages/Admin/Login/Login';
import AdminControls from './components/Pages/Admin/AdminControls/AdminControls';
import Logo from './assets/images/dsd_logo.svg';

// import AdminControls from './components/Pages/Admin/AdminControls/AdminControls';

// redux
import { connect } from 'react-redux';
import ReviewsPage from './components/Pages/Reviews/ReviewsPage';
import AboutKalkin from './components/Pages/AboutKalkin/AboutKalkin';
import AboutAditi from './components/Pages/AboutAditi/AboutAditi';
import Gallery from './components/Pages/Gallery/Gallery';
import NotFound from './components/Pages/NotFound.js/NotFound';
import SideLink from './components/SideLink/SideLink';
import Footer from './components/Footer/Footer';
import Friends from './components/Pages/Friends/Friends';
import Banner from './components/Banner/Banner';

const TRACKING_ID = 'UA-232902542-1';
ReactGA.initialize(TRACKING_ID);

function App(props) {
  return (
    <div className='App'>
      <React.StrictMode>
        <Overlay />
        <Router>
          <Banner>
            <p>
              Grab a FREE copy of rover today!{' '}
              <Link to='/book/rover'>Click Here</Link>
            </p>
          </Banner>
          <Header logo={Logo} />
          <SideLink title='Friends' to='/friends' />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route exact path='/book/:bookname' element={<Book />} />
            <Route exact path='/reviews' element={<ReviewsPage />} />
            <Route exact path='/gallery' element={<Gallery />} />
            <Route path='/about/rover' element={<AboutRover />} />
            <Route path='/about/kalkin' element={<AboutKalkin />} />
            <Route path='/about/aditi' element={<AboutAditi />} />
            <Route path='/friends' element={<Friends />} />

            <Route path='/admin' element={<Admin />}>
              <Route
                index
                element={
                  props.RX_user ? (
                    <Navigate replace to='/admin/reviews' />
                  ) : (
                    <Navigate replace to='/admin/login' />
                  )
                }
              />
              <Route
                path='/admin/login'
                element={
                  props.RX_user ? (
                    <Navigate replace to='/admin/reviews' />
                  ) : (
                    <Login />
                  )
                }
              />
              <Route
                path='/admin/reviews'
                element={
                  props.RX_user ? (
                    <AdminControls />
                  ) : (
                    <Navigate replace to='/admin/login' />
                  )
                }
                // element={<AdminControls />}
              />
            </Route>
            <Route path='/404' element={<NotFound />} />
            <Route path='*' element={<Navigate replace to='/404' />} />
          </Routes>
          <Footer
            logo={Logo}
            description='The most exciting books ever written by a Dog!'
            name='Delhi Street Dogs'
          >
            <Footer.Column title='Books'>
              <Footer.SiteLink toLink='/book/rover' title='Rover' />
              <Footer.SiteLink toLink='/book/kalkin' title='Kalkin' />
              <Footer.SiteLink toLink='/book/aditi' title='Aditi' />
            </Footer.Column>
            <Footer.Column title='About'>
              <Footer.SiteLink toLink='/about/rover' title='Rover' />
              <Footer.SiteLink toLink='/about/kalkin' title='Kalkin' />
              <Footer.SiteLink toLink='/about/aditi' title='Aditi' />
            </Footer.Column>
            <Footer.Column title='Explore'>
              <Footer.SiteLink toLink='/reviews' title='Reviews' />
              <Footer.SiteLink toLink='/gallery' title='Gallery' />
              <Footer.SiteLink toLink='/friends' title='Friends' />
            </Footer.Column>
          </Footer>
        </Router>
      </React.StrictMode>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    RX_user: state.setUser.user,
  };
}
export default connect(mapStateToProps)(App);
