import React, { useState, useEffect } from 'react';
import StarSelection from '../../StarSelection/StarSelection';
import ReactGA from 'react-ga';
import './reviewspage.css';

import Review from '../Book/ReviewFancy/Review';

//Firestore
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../../../firebase_config';
import { Helmet } from 'react-helmet-async';

const ReviewsPage = () => {
  const [reRenderPage, setRerenderPage] = useState(false);
  const [bookReviews, setBookReviews] = useState([]);

  const [starCount, setStarCount] = useState(0);
  const [bookSelection, setBookSelection] = useState('rover');
  const [reviewText, setReviewText] = useState('');
  const [reviewAuthor, setReviewAuthor] = useState('Anonymous Reader');

  const book_coll = 'rover_reviews';
  const bookReviewRef = collection(db, book_coll);

  const getBookReview = async () => {
    const queryRoverSnapshot = await getDocs(bookReviewRef);
    let tempBookReviews = [];
    queryRoverSnapshot.forEach((doc) => {
      tempBookReviews = [...tempBookReviews, doc.data()];
    });
    setBookReviews(tempBookReviews);
    setStarCount(0);
    setReviewText('');
    setReviewAuthor('');
  };

  useEffect(() => {
    getBookReview();

    ReactGA.pageview('/about/reviews');
  }, []);

  useEffect(() => {}, [reRenderPage]);

  const submitReviewHandler = async () => {
    if (window.confirm('Are you sure you would like to submit this review?')) {
      try {
        const newReview = await addDoc(collection(db, 'unapproved_reviews'), {
          created: Date.now(),
          approved: false,
          book: bookSelection,
          rating: starCount,
          review: reviewText,
          reviewer: reviewAuthor == '' ? 'Anonymous Reader' : reviewAuthor,
        });
        setRerenderPage(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Delhistreetdog Reviews</title>
        <meta
          name='description'
          content='Discover the reviews of the Delhi Street Dog'
        />
        <link rel='canonical' href='/reviews' />
      </Helmet>
      <div className='reviewsPage_wrapper'>
        <div className='reviewsPage_container'>
          <div className='reviewsPage_reviews_wrapper'>
            {bookReviews.map(({ rating, review, reviewer }) => {
              return (
                <div className='reviewsPage_review_container'>
                  <Review
                    key={review + Math.random()}
                    name={reviewer}
                    stars={rating}
                    review={review}
                  />
                </div>
              );
            })}
          </div>
          <div className='reviewsPage_review_form_wrapper'>
            <div className='reviewsPage_review_form_container'>
              <div className='review_form_row'>
                <input
                  className='review_form_name_input'
                  type='text'
                  min='2'
                  max='255'
                  placeholder='Name (Leave blank for anonymous)'
                  onChange={(e) => {
                    setReviewAuthor(e.target.value);
                  }}
                />
                <StarSelection
                  size={2}
                  starCount={starCount}
                  setStarCount={setStarCount}
                />
              </div>

              <textarea
                type='text'
                minLength='2'
                maxLength='1024'
                placeholder='Review'
                onChange={(e) => {
                  setReviewText(e.target.value);
                }}
              />
              <div className='review_form_row'>
                <select
                  onChange={(e) => {
                    setBookSelection(e.target.value);
                  }}
                  defaultValue='def'
                >
                  <option value='def' disabled>
                    Books...
                  </option>
                  <option value='rover'>Rover</option>
                  <option value='kalkin'>Kalkin</option>
                  <option value='aditi'>Aditi</option>
                </select>
                <p>Please note: Reviews are manually filtered for spam</p>
              </div>
              <button onClick={() => submitReviewHandler()}>
                Submit Review
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewsPage;
