import React, { useState } from 'react';

import Stars from '../../../Stars/Stars';

import './review.css';

const Review = ({ name, stars, review }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className='review_wrapper'>
      <div className='review_container'>
        <div className='review_title'>
          <p>{name}</p>
          <Stars count={stars} />
        </div>
        <div className='review_content'>
          <p>{showMore ? review : `${review.substring(0, 250)}`}</p>
          {review.length > 250 && (
            <button
              className='review_content_toggle'
              onClick={() => setShowMore((x) => !x)}
            >
              {showMore ? 'Less' : 'More'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Review;
