import React, { useEffect, useState } from 'react';
import './StarSelection.css';

import star from '../../assets/images/star.svg';
import star_e from '../../assets/images/star_empty.svg';

const StarSelection = ({ size, starCount, setStarCount }) => {
  const [starImages, setStarImages] = useState([
    star_e,
    star_e,
    star_e,
    star_e,
    star_e,
  ]);
  const [starHoverCount, setStarHoverCount] = useState(0);
  const [hoverStar, setHoverStar] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const leaveStar = (star_index) => {
    var tempStars = [false, false, false, false, false];
    setStarHoverCount(0);
    setHoverStar(tempStars);
  };
  const hoverOnStar = (star_index) => {
    var tempStars = [false, false, false, false, false];
    tempStars[star_index] = true;
    setStarHoverCount(star_index);
    setHoverStar(tempStars);
  };

  const clickOnStar = (star_index) => {
    var alteredStarIndex = star_index;
    if (star_index == 0 && starCount == 1) alteredStarIndex = -1;

    var tempStars = [star_e, star_e, star_e, star_e, star_e];
    for (var i = 0; i <= alteredStarIndex; i++) {
      tempStars[i] = star;
    }
    setStarCount(alteredStarIndex + 1);
    setStarImages(tempStars);
  };

  useEffect(() => {}, [starImages, starHoverCount]);

  return (
    <div className='Stars'>
      <ul style={{ height: size + 0.5 + 'rem' }}>
        {starImages.map((star, i) => {
          let key = 'star_set_' + i;
          return (
            <li key={key} style={{ width: size + 0.5 + 'rem' }}>
              <img
                style={{
                  height: hoverStar[i]
                    ? (size + 0.5 + 'rem').toString()
                    : (size + 'rem').toString(),
                  width: hoverStar[i]
                    ? (size + 0.5 + 'rem').toString()
                    : (size + 'rem').toString(),
                }}
                src={starImages[i]}
                alt='empty_star'
                onClick={() => {
                  clickOnStar(i);
                }}
                onMouseOver={() => {
                  hoverOnStar(i);
                }}
                onMouseLeave={() => {
                  leaveStar(i);
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default StarSelection;
