import React, { Children } from 'react';
import cw from 'classnames';
import Styles from './scatter.module.css';
import { useState } from 'react';

const Scatter = ({ children, photos }) => {
  const [photoCollection, setPhotoCollection] = useState(photos);

  return (
    <div className={Styles.Scatter}>
      {photoCollection.map((photo) => (
        <Card photo={photo} />
      ))}
    </div>
  );
};

const Card = ({ photo }) => {
  const [selected, setSelected] = useState(false);
  //   const [cardPos, setCardPos] = useState(pos);

  const handleSelect = () => {
    setSelected((sel) => !sel);
  };

  const randomRotation = () => {
    let sign = Math.floor(Math.random() * 2);
    if (sign === 1) {
      return -Math.floor(Math.random() * 31);
    } else {
      return Math.floor(Math.random() * 31);
    }
  };

  return (
    <div
      onClick={() => handleSelect()}
      className={cw(Styles.Backdrop, selected ? Styles.BD_Active : [])}
    >
      <div
        style={{
          transform: `rotate(${randomRotation()}deg)`,
        }}
        className={cw(Styles.Card, selected ? Styles.C_Active : [])}
      >
        <div className={Styles.ImageFilter} />
        <div className={Styles.Image}>
          <img className={Styles.TempImage} src={photo.image} />
          <p
            className={cw(Styles.ImagesDesc, selected ? Styles.ID_Active : [])}
          >
            {photo.desc}
          </p>
        </div>
      </div>
    </div>
  );
};

Scatter.Card = Card;

export default Scatter;
