import React, { useState } from 'react';
import { useEffect } from 'react';
import Client from '@shopify/buy-button-js';
import Price from '../Price/Price';
import BuyButton from './BuyButton/BuyButton';

import LoadingAnimated from '../../../LoadingAnimated/LoadingAnimated';
import Stars from '../../../Stars/Stars';

import defaultRoverCover from '../../../../assets/images/covers/rover.png';
import defaultAditiCover from '../../../../assets/images/covers/aditi.png';
import defaultKalkinCover from '../../../../assets/images/covers/kalkin.png';

const BookPurchase = ({ bookId, bookName, avgRating }) => {
  const [client, setClient] = useState();
  const [productInfo, setProductInfo] = useState({});

  const fetchClient = () => {
    try {
      if (client == undefined) {
        var clientTemp = Client.buildClient({
          domain: process.env.REACT_APP_PUBLIC_MY_API_URL,
          storefrontAccessToken: process.env.REACT_APP_MY_SECRET_API_TOKEN, // previously apiKey, now deprecated
        });
        setClient(clientTemp);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const getDefaultCover = (bookName) => {
    switch (bookName) {
      case 'rover':
        return defaultRoverCover;
      case 'aditi':
        return defaultAditiCover;
      case 'kalkin':
        return defaultKalkinCover;
      default:
        return null;
    }
  };
  const getBookNumber = (bookName) => {
    switch (bookName) {
      case 'rover':
        return 1;
      case 'aditi':
        return 3;
      case 'kalkin':
        return 2;
      default:
        return null;
    }
  };

  const fetchProduct = () => {
    try {
      if (client != undefined && productInfo != {}) {
        client.product
          .fetch('gid://shopify/Product/' + bookId)
          .then((product) => {
            setProductInfo({
              price: product.variants[0].price,
              id: bookId,
              image: product.images[0].src,
            });
          });
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchClient();
    fetchProduct();
  }, [client, bookId]);
  return (
    <div className='book_infomation_wrapper'>
      <div className='book_infomation_container'>
        <div className='book_infomation_cover_wrapper'>
          <div className='book_infomation_cover_container'>
            {productInfo.image ? (
              <img src={productInfo.image} alt='Book Cover' />
            ) : getDefaultCover(bookName) ? (
              <img src={getDefaultCover(bookName)} alt='Book Cover' />
            ) : (
              <LoadingAnimated />
            )}
          </div>
        </div>
        <div className='book_data_wrapper'>
          <div className='book_data_container'>
            <ul>
              <li>
                <h1>{bookName.toUpperCase()}</h1>
              </li>
              <li>
                <h3>BOOK {getBookNumber(bookName)}</h3>
              </li>
              <li>
                {avgRating ? (
                  <Stars size={2} count={avgRating} />
                ) : (
                  'No Ratings'
                )}
              </li>
              <li>
                {/* {console.log(productInfo.price?.attrs.amount.value)} */}
                {productInfo.price?.attrs?.amount?.value != undefined && (
                  <h2>
                    {productInfo.price?.attrs?.amount?.value == undefined
                      ? 'Loading'
                      : productInfo.price?.attrs?.amount?.value == '0.0'
                      ? 'FREE'
                      : '£' +
                        parseFloat(
                          productInfo.price?.attrs?.amount?.value
                        ).toFixed(2)}
                  </h2>
                )}
              </li>
              <li className={bookName + '_bg_color'}>
                <BuyButton product={productInfo} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookPurchase;
