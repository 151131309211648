import React from 'react';
import cw from 'classnames';

import Styles from './footer.module.css';

import { Link } from 'react-router-dom';

const Footer = ({ children, description, logo, name }) => {
  return (
    <div className={Styles.Footer}>
      <div className={Styles.Footer_Wrapper}>
        <div className={Styles.Footer_Container}>
          <div className={cw(Styles.Footer_Column, Styles.Footer_Logo)}>
            <img src={logo} alt='logo' />
            <p style={{ fontSize: '1rem' }}>{description}</p>
          </div>
          <div className={Styles.Footer_Column_Wrapper}>{children}</div>
        </div>
      </div>
      <div className={Styles.Footer_Copyright}>
        <p>
          <span
            style={{
              fontFamily: 'arial',
            }}
          >
            ©
          </span>{' '}
          2022 {name.toUpperCase()}
        </p>
      </div>
    </div>
  );
};

const Column = ({ title, children }) => {
  return (
    <div className={Styles.Footer_Column}>
      <p>
        <b>{title}</b>
      </p>
      {children}
    </div>
  );
};

const SiteLink = ({ title, toLink }) => {
  return <Link to={toLink}>{title}</Link>;
};

Footer.Column = Column;
Footer.SiteLink = SiteLink;

export default Footer;
