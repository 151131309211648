import React from 'react';
import './home_mobile.css';
// import './home_tablet.css';
import './home_desktop.css';

import delhi_background_desktop from '../../../assets/images/Home/landing_image_desktop_draft.png';
import delhi_background_mobile from '../../../assets/images/Home/landing_image_mobile_draft.png';

import rover from '../../../assets/images/dog_circle.png';
import pups from '../../../assets/images/dogs_transparent.png';

import { useDispatch } from 'react-redux';
import { toggleHeader } from '../../../redux/actions/headerActions';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

function Home() {
  // const dispatch = useDispatch();
  // useLayoutEffect(() => {
  //   console.log('Toggle header as redirect');
  //   dispatch(toggleHeader());
  // }, []);
  useEffect(() => {
    ReactGA.pageview('/');
  }, []);

  return (
    <>
      <Helmet>
        <title>Delhistreetdog</title>
        <meta
          name='description'
          content='Discover the mysteries of Kalkin the Delhi Street Dog'
        />
        <link rel='canonical' href='/' />
      </Helmet>
      <div className='Home'>
        <section className='landing_container'>
          <picture>
            <source
              media='(min-width: 700px)'
              srcSet={delhi_background_desktop}
            />
            <img
              alt='delhi temple'
              width='100%'
              src={delhi_background_mobile}
            />
          </picture>
          <div className='landing_text_wrapper'>
            <div className='landing_text_container'>
              <p>Hello there, </p>
              <p>I am so glad you made it...</p>
              <h1>
                I can't wait to tell you the stories about being a delhi street
                dog!
              </h1>
            </div>
          </div>

          <div className='custom-shape-divider-bottom-1641595024'>
            <svg
              data-name='Layer 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 1200 120'
              preserveAspectRatio='none'
            >
              <path
                d='M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z'
                className='shape-fill'
              ></path>
            </svg>
          </div>
        </section>

        <section className='section_container content1_container'>
          <div className='content_wrapper'>
            <div className='content1'>
              <p>
                This picture was taken when I was lying on a wall at the tomb of
                Humayun in Old Delhi. <br />
                Aren't I lucky, to have been born so near to some of the
                loveliest old buildings in India and therefore in the world!
              </p>
              <img alt='delhi street dog rover' src={rover} />
            </div>
          </div>
        </section>

        <section className='section_container  content2_container'>
          <div className='custom-shape-divider-top-1641595828'>
            <svg
              data-name='Layer 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 1200 120'
              preserveAspectRatio='none'
            >
              <path
                d='M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z'
                className='shape-fill'
              ></path>
            </svg>
          </div>
          <div className='content_wrapper'>
            <div className='content2'>
              <img
                alt='delhi street dog rover kalkin aditi puppies'
                src={pups}
              />
              <p>
                Of course, Delhi street dogs don't live in buildings like the
                tomb of Humayun; we live where people don't disturb, capture or
                kill us. This is where I grew up, in a little corner of a little
                park, just near the Red Fort in Old Delhi.
              </p>
            </div>
          </div>
          <div className='custom-shape-divider-bottom-1641595891'>
            <svg
              data-name='Layer 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 1200 120'
              preserveAspectRatio='none'
            >
              <path
                d='M1200 0L0 0 892.25 114.72 1200 0z'
                className='shape-fill'
              ></path>
            </svg>
          </div>
        </section>

        <section className='section_container  content3_container'>
          <div className='content_wrapper'>
            <div className='content3'>
              <p>
                The tourists at the Red Fort think 'Wow! What a wonderful
                building!' I agree with them! Then they eat a samosa or
                something from a snack seller, and say 'I'm so full, I couldn't
                eat another thing!' Because I can understand what they're
                saying, I come in, and grab the food as they throw it away. Then
                I take it to my Mum and my little brothers and sisters.
              </p>
            </div>
          </div>
        </section>

        <section className='section_container footer'>
          <div className='custom-shape-divider-top-1641595930'>
            <svg
              data-name='Layer 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 1200 120'
              preserveAspectRatio='none'
            >
              <path
                d='M1200 0L0 0 598.97 114.72 1200 0z'
                className='shape-fill'
              ></path>
            </svg>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
