import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_DOMAIN}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  projectId: 'delhistreetdogs-3f6ea',
  storageBucket: 'delhistreetdogs-3f6ea.appspot.com',
  messagingSenderId: '257967678410',
  appId: '1:257967678410:web:0d20ee17c585b2e644a533',
  measurementId: 'G-TB9KZ30MR1',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
