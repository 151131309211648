import { headerActionTypes } from '../constants/headerActionTypes';

const initialState = {
  header_desktop: true,
};
export const headerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case headerActionTypes.SET_HEADER_TOGGLE:
      return { ...state, header_desktop: !state.header_desktop };
    default:
      return state;
  }
};
