import React from 'react';

import './aboutkalkin_mobile.css';
import './aboutkalkin_desktop.css';

import Postcard from '../../../assets/images/kalkin/Postcard.png';
import Kochi from '../../../assets/images/kalkin/Kochi.png';
import Fishing_Nets_Postcard from '../../../assets/images/kalkin/fishing_nets_postcard.png';
import Mumbai from '../../../assets/images/kalkin/Mumbai.png';
import Mumbai_Postcard from '../../../assets/images/kalkin/mumbai_postcard.png';
import Question_Mark from '../../../assets/images/kalkin/question_mark.png';
import Kerala from '../../../assets/images/kalkin/Kerala.png';
import Kerala_Postcard from '../../../assets/images/kalkin/jungle_postcard.png';

import RouteOne from '../../../assets/images/kalkin/Routes/Route_1.png';
import RouteTwo from '../../../assets/images/kalkin/Routes/Route_2.png';
import RouteThree from '../../../assets/images/kalkin/Routes/Route_3.png';
import RouteFour from '../../../assets/images/kalkin/Routes/Route_4.png';
import RouteFive from '../../../assets/images/kalkin/Routes/Route_5.png';

import { Controller, Scene } from 'react-scrollmagic';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const AboutKalkin = () => {
  useEffect(() => {
    ReactGA.pageview('/about/kalkin');
  }, []);

  return (
    <>
      <Helmet>
        <title>Delhistreetdog Kalkin</title>
        <meta
          name='description'
          content='Discover the mysteries of Kalkin the Delhi Street Dog'
        />
        <link rel='canonical' href='/about/kalkin' />
      </Helmet>
      <div className='aboutKalkin'>
        <Controller>
          <section className='aboutKalkinSection kalkinSection1'>
            <div className='trigger' id='kalkinSection1Postcard' />
            <div className='book_intro'>
              <h1 className='title'>KALKIN</h1>
              <p className='subtext'>Please Scroll</p>
            </div>
            <Link className='goToStore' to='/book/kalkin'>
              Book Store →
            </Link>
            <img
              className='postcard'
              alt='mohini postcard kalkin'
              src={Postcard}
            />
            <Scene
              duration={0}
              classToggle='postcardTriggered'
              triggerElement='#kalkinSection1Postcard'
              // indicators={true}
            >
              <div>
                <img src={RouteOne} alt='route to kochi' className='route' />
              </div>
            </Scene>
          </section>
          <section className='aboutKalkinSection kalkinSection2'>
            <div className='trigger' id='kalkinSection2Text1Trigger' />
            <div className='trigger' id='kalkinSection2Text2Trigger' />
            <div className='trigger' id='kalkinSection2MapTrigger' />

            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#kalkinSection2Text1Trigger'
              // indicators={true}
            >
              <p className='center text1'>
                So I moved with my new mistress the dancer Mohini to her home,
                Kochi. I love just sitting here watching them fishing with these
                amazing Chinese nets!
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#kalkinSection2Text2Trigger'
              // indicators={true}
            >
              <p className='center text2'>
                Then a black plastic bag was caught in one of these nets… and
                life will never be the same again
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#kalkinSection2MapTrigger'
              // indicators={true}
            >
              <div>
                <img src={RouteTwo} alt='route to kochi' className='route' />

                <img className='kochimap' alt='map of kochi' src={Kochi} />
                <img
                  className='kochipostcard'
                  alt='postcard of kochi'
                  src={Fishing_Nets_Postcard}
                />
              </div>
            </Scene>
          </section>
          <section className='aboutKalkinSection kalkinSection3'>
            <div className='trigger' id='kalkinSection3Text1Trigger' />
            <div className='trigger' id='kalkinSection3Text2Trigger' />
            <div className='trigger' id='kalkinSection3MapTrigger' />

            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#kalkinSection3Text1Trigger'
              // indicators={true}
            >
              <p className='center text1'>
                But our adventure didn't start here; it started in Mumbai…
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#kalkinSection3Text2Trigger'
              // indicators={true}
            >
              <p className='center text2'>
                It started here… a hundred metres up on the outside of a
                skyscraper in Mumbai
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#kalkinSection3MapTrigger'
              // indicators={true}
            >
              <div>
                <img src={RouteThree} alt='route to mumbai' className='route' />
                <img className='mumbaimap' alt='map of mumbai' src={Mumbai} />
                <img
                  className='mumbaipostcard'
                  alt='postcard of mumbai'
                  src={Mumbai_Postcard}
                />
              </div>
            </Scene>
          </section>
          <section className='aboutKalkinSection kalkinSection4'>
            <div className='trigger' id='kalkinSection4TextTrigger' />
            <div className='trigger' id='kalkinSection4Text2Trigger' />
            <div className='trigger' id='kalkinSection4MapTrigger' />
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#kalkinSection4TextTrigger'
              // indicators={true}
            >
              <p className='center text1'>
                So the Investigations Bureau set up by Rin and Mohini suddenly
                got very busy…
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='text2Triggered'
              triggerElement='#kalkinSection4Text2Trigger'
              // indicators={true}
            >
              <p className='center text2'>
                Why was that black plastic bag floating in the sea by the
                Chinese fishing nets? Where did it come from? Was the man
                floating face down in the water
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#kalkinSection4MapTrigger'
              // indicators={true}
            >
              <div>
                <img
                  src={RouteFour}
                  alt='route to questionmark'
                  className='route'
                />
                <img
                  className='questionmark'
                  alt='question mark kalkin'
                  src={Question_Mark}
                />
              </div>
            </Scene>
          </section>
          <section className='aboutKalkinSection kalkinSection5'>
            <div className='trigger' id='kalkinSection5TextTrigger' />
            <div className='trigger' id='kalkinSection5MapTrigger' />
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#kalkinSection5TextTrigger'
              // indicators={true}
            >
              <p className='center text1'>
                And these are the backwaters of Kerala, where they kept Emily
                after the kidnapping
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#kalkinSection5TextTrigger'
              // indicators={true}
            >
              <p className='center text2'>
                and the trail led us here… can you guess?
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#kalkinSection5MapTrigger'
              // indicators={true}
            >
              <div>
                <img src={RouteFive} alt='route to kerala' className='route' />
                <img className='keralamap' alt='map of kerala' src={Kerala} />
                <img
                  className='keralapostcard'
                  alt='postard of kerala'
                  src={Kerala_Postcard}
                />
              </div>
            </Scene>
          </section>
        </Controller>
      </div>
    </>
  );
};

export default AboutKalkin;
