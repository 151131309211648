import React, { useEffect, useState } from 'react';
import Tabs from '../../../Tabs/Tabs';
import './admincontrols.css';
import BookReview from './Reviews/BookReview';

//Firestore stuff
import { collection, doc, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebase_config';

// redux
import { useDispatch, connect } from 'react-redux';
import { reviewAction } from '../../../../redux/actions/reviewActions';

const AdminControls = (props) => {
  // console.log('rerender admin');
  const [reviews, setReviews] = useState({});

  const dispatch = useDispatch();

  const pendingRef = collection(db, 'unapproved_reviews');
  const declinedRef = collection(db, 'declined_reviews');
  const roverRef = collection(db, 'rover_reviews');
  const kalkinRef = collection(db, 'kalkin_reviews');
  const aditiRef = collection(db, 'aditi_review');

  const fetchBookReviews = async () => {
    // console.log('Getting new data');
    var tempReviews = {
      pending: [],
      declined: [],
      rover: [],
      kalkin: [],
      aditi: [],
    };

    const queryPendingSnapshot = await getDocs(pendingRef);
    queryPendingSnapshot.forEach((doc) => {
      tempReviews.pending = [...tempReviews.pending, doc];
    });
    const queryDeclinedSnapshot = await getDocs(declinedRef);
    queryDeclinedSnapshot.forEach((doc) => {
      tempReviews.declined = [...tempReviews.declined, doc];
    });
    const queryRoverSnapshot = await getDocs(roverRef);
    queryRoverSnapshot.forEach((doc) => {
      tempReviews.rover = [...tempReviews.rover, doc];
    });
    const queryKalkinSnapshot = await getDocs(kalkinRef);
    queryKalkinSnapshot.forEach((doc) => {
      tempReviews.kalkin = [...tempReviews.kalkin, doc];
    });
    const queryAditiSnapshot = await getDocs(aditiRef);
    queryAditiSnapshot.forEach((doc) => {
      tempReviews.aditi = [...tempReviews.aditi, doc];
    });

    setReviews(tempReviews);
  };

  useEffect(() => {
    fetchBookReviews();
  }, []);

  // useEffect(() => {
  //   dispatch(reviewAction(reviews));
  // }, [reviews]);
  // console.log('State', reviews);
  return (
    <div className='admincontrols_wrapper'>
      <div className='admincontrols_container'>
        <Tabs data={reviews}>
          <Tabs.Tab
            tabElement={
              <BookReview book='pending' bookReviewData={reviews.pending} />
            }
          >
            Pending
          </Tabs.Tab>
          <Tabs.Tab
            tabElement={
              <BookReview book='declined' bookReviewData={reviews.declined} />
            }
          >
            Declined
          </Tabs.Tab>
          <Tabs.Tab
            tabElement={
              <BookReview book='rover' bookReviewData={reviews.rover} />
            }
          >
            Rover
          </Tabs.Tab>
          <Tabs.Tab
            tabElement={
              <BookReview book='kalkin' bookReviewData={reviews.kalkin} />
            }
          >
            Kalkin
          </Tabs.Tab>
          <Tabs.Tab
            tabElement={
              <BookReview book='aditi' bookReviewData={reviews.aditi} />
            }
          >
            Aditi
          </Tabs.Tab>
        </Tabs>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    RX_reviews: state.reviewRed,
  };
}
export default connect(mapStateToProps)(AdminControls);
