import { combineReducers } from 'redux';
import { headerReducer } from './headerReducer';
import { userReducer } from './userAuthReducer';
import { reviewReducer } from './reviewReducer';

const reducers = combineReducers({
  toggleHeader: headerReducer,
  setUser: userReducer,
  reviewRed: reviewReducer,
});

export default reducers;
