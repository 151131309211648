import React, { useEffect, useState } from 'react';
import './tab.css';

const Tabs = ({ children, data }) => {
  // console.log('Tabs data', data);

  // const [tabsContent, setTabsContent] = useState([]);
  const [activeTab, setActiveTab] = useState();
  // console.log(children);

  var tabsContent = [];
  // var activeTab;

  useEffect(() => {
    tabsContent = React.Children.map(children, (child, i) => {
      return child.props.tabElement;
    });
    // console.log('CHILDREN MOUNTED -------------');
  });

  return (
    <div className='tab_wrapper'>
      {/* {console.log('-----------Tabs Rendered-------')} */}
      <div className='tab_container'>
        <div className='tab_headers'>
          {children.map((child, i) => (
            <div
              key={Math.random()}
              onClick={() => setActiveTab(tabsContent[i])}
              className='tab_header'
            >
              {child}
            </div>
          ))}
        </div>
        <div className='tab_contents'>
          {!activeTab ? <p>Please select a state from above</p> : activeTab}
        </div>
      </div>
    </div>
  );
};

const Tab = ({ children, tabElement, date }) => {
  return (
    <div className='tab'>
      {/* {console.log('Tab Rendered', tabElement)} */}
      <p>{children}</p>
    </div>
  );
};

Tabs.Tab = Tab;

export default Tabs;
