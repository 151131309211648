import React, { useEffect } from 'react';
import ShopifyBuy from '@shopify/buy-button-js';

const shopifyClient = ShopifyBuy.buildClient({
  domain: 'delhistreetdogs.myshopify.com/',
  storefrontAccessToken: 'ffa5d83d7a028ed164ad1f97a0060c76',
});

const ui = ShopifyBuy.UI.init(shopifyClient);

export default function BuyNow({ id }) {
  useEffect(() => {
    ui.createComponent('product', {
      iframe: false,
      options: {
        button: {
          iframe: false,
        },
        classes: {
          button: 'shopify-buy__asdasd',
        },
      },
      node: document.getElementById(`buy-now-${id}`),
    });
  });

  return <div id={`buy-now-${id}`} />;
}
