import React from 'react';

import './aboutrover_mobile.css';
import './aboutrover_desktop.css';

import Delhi from '../../../assets/images/rover/Delhi.png';
import Thar from '../../../assets/images/rover/Thar.png';
import Jaipur from '../../../assets/images/rover/Jaipur.png';
import Udaipur from '../../../assets/images/rover/Udaipur.png';
import DelhiPostcard from '../../../assets/images/rover/Postcard_Red_fort.png';
import TharPostcard from '../../../assets/images/rover/Postcard_desert.png';
import JaipurPostcard from '../../../assets/images/rover/Postcard_Amer_Fort.png';
import UdaipurPostcard from '../../../assets/images/rover/Postcard _Palace.png';
import Postcard from '../../../assets/images/rover/Postcard.png';

import RouteOne from '../../../assets/images/rover/route_1.png';
import RouteTwo from '../../../assets/images/rover/route_2.png';
import RouteThree from '../../../assets/images/rover/route_3.png';
import RouteFour from '../../../assets/images/rover/route_4.png';

import { Controller, Scene } from 'react-scrollmagic';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const AboutRover = () => {
  useEffect(() => {
    ReactGA.pageview('/about/rover');
  }, []);
  return (
    <>
      <Helmet>
        <title>Delhistreetdog Rover</title>
        <meta
          name='description'
          content='Discover the mysteries of Rover the Delhi Street Dog'
        />
        <link rel='canonical' href='/about/rover' />
      </Helmet>
      <div className='aboutRover'>
        <Controller>
          <section className='aboutRoverSection roverSection1'>
            <div className='trigger' id='roverSection1Postcard' />
            <div className='book_intro'>
              <h1 className='title'>ROVER</h1>
              <p className='subtext'>Please Scroll</p>
            </div>
            <Link className='goToStore' to='/book/rover'>
              Book Store →
            </Link>
            <img
              className='postcard'
              alt='Lottie Postcard Rover'
              src={Postcard}
            />
            <Scene
              duration={0}
              classToggle='postcardTriggered'
              triggerElement='#roverSection1Postcard'
              // indicators={true}
            >
              <div>
                <img src={RouteTwo} alt='Route to delhi' className='route' />
              </div>
            </Scene>
          </section>
          <section className='aboutRoverSection roverSection2'>
            <div className='trigger' id='roverSection2TextTrigger' />
            <div className='trigger' id='roverSection2MapTrigger' />

            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#roverSection2TextTrigger'
              // indicators={true}
            >
              <p className='text1'>
                I love the Red Fort at night; the pride of Delhi! One night I
                found more than just food for the family...
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#roverSection2MapTrigger'
              // indicators={true}
            >
              <div>
                <img className='delhimap' alt='Map of delhi' src={Delhi} />
                <img
                  className='delhipostcard'
                  alt='delhi label'
                  src={DelhiPostcard}
                />
              </div>
            </Scene>
          </section>
          <section className='aboutRoverSection roverSection3'>
            <div className='trigger' id='roverSection3TextTrigger' />
            <div className='trigger' id='roverSection3MapTrigger' />

            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#roverSection3TextTrigger'
              // indicators={true}
            >
              <p className='text1'>
                a Professor of Archaeology who's very excited as she is off to
                the Thar desert to make sense of ancient carvings she found at
                the red fort...
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#roverSection3MapTrigger'
              // indicators={true}
            >
              <div>
                <img
                  src={RouteOne}
                  alt='route to thar desert'
                  className='route'
                />
                <img className='tharmap' alt='map of thar desert' src={Thar} />
                <img
                  className='tharpostcard'
                  alt='postcard of thar desert'
                  src={TharPostcard}
                />
              </div>
            </Scene>
          </section>
          <section className='aboutRoverSection roverSection4'>
            <div className='trigger' id='roverSection4TextTrigger' />
            <div className='trigger' id='roverSection4Text2Trigger' />
            <div className='trigger' id='roverSection4MapTrigger' />
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#roverSection4TextTrigger'
              // indicators={true}
            >
              <p className='text1'>
                from the Thar desert the trail led on to the fabulous Amer Fort
                in Jaipur!
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='text2Triggered'
              triggerElement='#roverSection4Text2Trigger'
              // indicators={true}
            >
              <p className='text2'>
                You wouldn't expect to find dead bodies in a charming tourist
                venue like this would you. Even if they were five hundred years
                old…
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#roverSection4MapTrigger'
              // indicators={true}
            >
              <div>
                <img src={RouteTwo} alt='route to jaipur' className='route' />
                <img className='jaipurmap' alt='map of jaipur' src={Jaipur} />
                <img
                  className='jaipurpostcard'
                  alt='postcard of jairpur'
                  src={JaipurPostcard}
                />
              </div>
            </Scene>
          </section>
          <section className='aboutRoverSection roverSection5'>
            <div className='trigger' id='roverSection5TextTrigger' />
            <div className='trigger' id='roverSection5MapTrigger' />
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#roverSection5TextTrigger'
              // indicators={true}
            >
              <p className='text1'>
                … and on to the the City Palace at Udaipur, where I learned how
                to abseil!
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#roverSection5MapTrigger'
              // indicators={true}
            >
              <div>
                <img
                  src={RouteThree}
                  alt='route to udaipur'
                  className='route'
                />
                <img className='udaipurmap' alt='adaipur map' src={Udaipur} />
                <img
                  className='udaipurpostcard'
                  alt='postcard of adaipur'
                  src={UdaipurPostcard}
                />
              </div>
            </Scene>
          </section>
        </Controller>
      </div>
    </>
  );
};

export default AboutRover;
