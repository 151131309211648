import React, { useState } from 'react';
import './stars.css';

import star from '../../assets/images/star.svg';
import star_e from '../../assets/images/star_empty.svg';
import { useEffect } from 'react';

const Stars = ({ count, size }) => {
  const [starCount, setStarCount] = useState(
    count > 5 ? 5 : count < 0 ? 0 : count
  );

  return (
    <div className='Stars'>
      <ul>
        {[...Array(Math.round(starCount))].map((i) => (
          <li key={Math.random()}>
            {size ? (
              <img
                key='uniqueId1'
                src={star}
                alt='star'
                style={{ width: size + 'rem', height: size + 'rem' }}
              />
            ) : (
              <img key='uniqueId1' src={star} alt='star' />
            )}
          </li>
        ))}
        {[...Array(5 - Math.round(starCount))].map((i) => (
          <li key={Math.random()}>
            {size ? (
              <img
                key='uniqueId1'
                src={star_e}
                alt='empty_star'
                style={{ width: size + 'rem', height: size + 'rem' }}
              />
            ) : (
              <img key='uniqueId1' src={star_e} alt='empty_star' />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Stars;
