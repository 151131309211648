import React from 'react';

import './aboutaditi_mobile.css';
import './aboutaditi_desktop.css';

import Postcard from '../../../assets/images/aditi/Postcard.png';
import Major from '../../../assets/images/aditi/major.png';
import Stone from '../../../assets/images/aditi/stone.png';
import Kochi from '../../../assets/images/aditi/Kochi.png';
import Kochi_Postcard from '../../../assets/images/aditi/show_postcard.png';
import Sarcophagus from '../../../assets/images/aditi/sarcophagus.png';
import Kolkata from '../../../assets/images/aditi/Kolkota.png';
import Kolkata_Postcard from '../../../assets/images/aditi/kolkata_postcard.png';
import Girl from '../../../assets/images/aditi/Girl.png';

import RouteOne from '../../../assets/images/aditi/Routes/Route_1.png';
import RouteTwo from '../../../assets/images/aditi/Routes/Route_2.png';
import RouteThree from '../../../assets/images/aditi/Routes/Route_3.png';
import RouteFour from '../../../assets/images/aditi/Routes/Route_4.png';
import RouteFive from '../../../assets/images/aditi/Routes/Route_5.png';
import RouteSix from '../../../assets/images/aditi/Routes/Route_6.png';

import { Controller, Scene } from 'react-scrollmagic';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const AboutAditi = () => {
  useEffect(() => {
    ReactGA.pageview('/about/aditi');
  }, []);

  return (
    <>
      <Helmet>
        <title>Delhistreetdog Aditi</title>
        <meta
          name='description'
          content='Discover the mysteries of Aditi the Delhi Street Dog'
        />
        <link rel='canonical' href='/about/aditi' />
      </Helmet>
      <div className='aboutAditi'>
        <Controller>
          <section className='aboutAditiSection aditiSection1'>
            <div className='trigger' id='aditiSection1Postcard' />
            <div className='book_intro'>
              <p className='title'>ADITI</p>
              <p className='subtext'>Please Scroll</p>
            </div>

            {/* <Link className='goToStore' to='/book/aditi'>
            Book Store →
          </Link> */}
            <div className='goToStore'>Coming Soon</div>

            <img className='postcard' src={Postcard} />
            <Scene
              duration={0}
              classToggle='postcardTriggered'
              triggerElement='#aditiSection1Postcard'
              // indicators={true}
            >
              <div>
                <img src={RouteOne} alt='Route' className='route' />
              </div>
            </Scene>
          </section>
          <section className='aboutAditiSection aditiSection2'>
            <div className='trigger' id='aditiSection2Text1Trigger' />
            <div className='trigger' id='aditiSection2Text2Trigger' />
            <div className='trigger' id='aditiSection2MapTrigger' />

            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#aditiSection2Text1Trigger'
              // indicators={true}
            >
              <p className='center text1'>
                This is Major Dev Bedi Of the National Security Guard (nicknamed
                the Black Cats)
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#aditiSection2Text2Trigger'
              // indicators={true}
            >
              <p className='center text2'>
                Loved him as soon as I met him. Still do. He named me Aditi.
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#aditiSection2MapTrigger'
              // indicators={true}
            >
              <div>
                <img src={RouteTwo} alt='Route' className='route' />

                <img className='Major' src={Major} />
              </div>
            </Scene>
          </section>
          <section className='aboutAditiSection aditiSection3'>
            <div className='trigger' id='aditiSection3TextTrigger' />
            <div className='trigger' id='aditiSection3MapTrigger' />

            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#aditiSection3TextTrigger'
              // indicators={true}
            >
              <p className='center text1'>
                This is how it all began, someone stole this gorgeous uncut
                ruby, given to Rin’s mother by me, then inherited by Rin when
                her mother died
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#aditiSection3MapTrigger'
              // indicators={true}
            >
              <div>
                <img src={RouteThree} alt='Route' className='route' />
                <img className='Stone' src={Stone} />
              </div>
            </Scene>
          </section>
          <section className='aboutAditiSection aditiSection4'>
            <div className='trigger' id='aditiSection4TextTrigger' />
            <div className='trigger' id='aditiSection4MapTrigger' />
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#aditiSection4TextTrigger'
              // indicators={true}
            >
              <p className='text1'>
                Then somebody blew up the Kathakali theatre in Old Kochi,
                injuring Mohini and Rin, and killing two of the musicians
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#aditiSection4MapTrigger'
              // indicators={true}
            >
              <div>
                <img src={RouteFour} alt='Route' className='route' />
                <img className='kochi_map' src={Kochi} />
                <img className='kochi_postcard' src={Kochi_Postcard} />
              </div>
            </Scene>
          </section>
          <section className='aboutAditiSection aditiSection5'>
            <div className='trigger' id='aditiSection5TextTrigger' />
            <div className='trigger' id='aditiSection5MapTrigger' />
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#aditiSection5TextTrigger'
              // indicators={true}
            >
              <p className='center text1'>
                And then the explosion at the catchily named Chhatrapati Shivaji
                Maharaj Vastu Sangrahalaya, and the daring theft of the solid
                gold sarcophagus!
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#aditiSection5MapTrigger'
              // indicators={true}
            >
              <div>
                <img src={RouteFive} alt='Route' className='route' />
                <img className='Sarcophagus' src={Sarcophagus} />
              </div>
            </Scene>
          </section>
          <section className='aboutAditiSection aditiSection6'>
            <div className='trigger' id='aditiSection6TextTrigger' />
            <div className='trigger' id='aditiSection6MapTrigger' />
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#aditiSection6TextTrigger'
              // indicators={true}
            >
              <p className='center text1'>
                The trail led us to Kolkata; this is where the humble clerks
                slaving away at their desks used to work in the days of the East
                India Company. Whilst here, we recruited a new team member.
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#aditiSection6MapTrigger'
              // indicators={true}
            >
              <div>
                <img src={RouteSix} alt='Route' className='route' />
                <img className='kolkata_map' src={Kolkata} />
                <img className='kolkata_postcard' src={Kolkata_Postcard} />
              </div>
            </Scene>
          </section>
          <section className='aboutAditiSection aditiSection7'>
            <div className='trigger' id='aditiSection7TextTrigger' />
            <div className='trigger' id='aditiSection7MapTrigger' />
            <Scene
              duration={0}
              classToggle='textTriggered'
              triggerElement='#aditiSection7TextTrigger'
              // indicators={true}
            >
              <p className='center text1'>
                It turned out she had skills none of us had, but which we
                couldn’t have survived without!
              </p>
            </Scene>
            <Scene
              duration={0}
              classToggle='mapTriggered'
              triggerElement='#aditiSection7MapTrigger'
              // indicators={true}
            >
              <div>
                <img className='Girl' src={Girl} />
              </div>
            </Scene>
          </section>
        </Controller>
      </div>
    </>
  );
};

export default AboutAditi;
