import { userAuthActionTypes } from '../constants/userAuthActionTypes';

const initialState = {
  user: {},
};
export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case userAuthActionTypes.SET_USER_AUTH:
      return { ...state, user: payload };
    default:
      return state;
  }
};
