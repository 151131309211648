import React from 'react';
import cw from 'classnames';
import Styles from './gallery.module.css';
import Scatter from '../../Scatter/Scatter';

import gallery1 from '../../../assets/images/gallery/gallery1.jpeg';
import gallery2 from '../../../assets/images/gallery/gallery2.jpeg';
import gallery3 from '../../../assets/images/gallery/gallery3.jpeg';
import gallery4 from '../../../assets/images/gallery/gallery4.jpeg';
import gallery5 from '../../../assets/images/gallery/gallery5.jpeg';
import gallery6 from '../../../assets/images/gallery/gallery6.jpeg';
import gallery7 from '../../../assets/images/gallery/gallery7.jpeg';
import gallery8 from '../../../assets/images/gallery/gallery8.jpeg';
import gallery9 from '../../../assets/images/gallery/gallery9.jpeg';
import gallery10 from '../../../assets/images/gallery/gallery10.jpeg';
import gallery11 from '../../../assets/images/gallery/gallery11.jpeg';
import gallery12 from '../../../assets/images/gallery/gallery12.jpeg';
import gallery13 from '../../../assets/images/gallery/gallery13.jpeg';
import gallery14 from '../../../assets/images/gallery/gallery14.jpeg';
import gallery15 from '../../../assets/images/gallery/gallery15.jpeg';
import gallery16 from '../../../assets/images/gallery/gallery16.jpeg';
import gallery17 from '../../../assets/images/gallery/gallery17.jpeg';
import gallery18 from '../../../assets/images/gallery/gallery18.jpeg';
import gallery19 from '../../../assets/images/gallery/gallery19.jpeg';
import gallery20 from '../../../assets/images/gallery/gallery20.jpeg';
import gallery21 from '../../../assets/images/gallery/gallery21.jpeg';
import gallery22 from '../../../assets/images/gallery/gallery22.jpeg';
import gallery23 from '../../../assets/images/gallery/gallery23.jpeg';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const Gallery = () => {
  useEffect(() => {
    ReactGA.pageview('/gallery');
  }, []);
  return (
    <>
      <Helmet>
        <title>Delhistreetdog Gallery</title>
        <meta
          name='description'
          content='Discover the photos which the Delhi Sreet Dog has collected!'
        />
        <link rel='canonical' href='/gallery/' />
      </Helmet>
      <div className={Styles.Gallery}>
        <div className={Styles.Header}>
          <h1>Please click on a photo to view it</h1>
        </div>
        <Scatter
          photos={[
            {
              image: gallery1,
              desc: 'The bookshop in the Khan Market in Delhi where you go to calmly recharge your batteries!',
            },
            {
              image: gallery6,
              desc: `Typical tourists at the Taj Mahal. Bet they don't know what we know!`,
            },
            { image: gallery9, desc: 'An Eagle above the Red Fort in Delhi' },
            { image: gallery10, desc: 'Tour boat on the backwaters of Kerala' },
            {
              image: gallery11,
              desc: 'The island where they kept her prisoner...',
            },
            {
              image: gallery12,
              desc: 'The Chenda drummers playing as loudly as only they can, the night we liberated the captive heiress',
            },
            { image: gallery13, desc: 'GUESS WHO?' },
            { image: gallery14, desc: 'My train to Mumbai' },
            {
              image: gallery15,
              desc: 'The cafe in Goa where the ex-pro boxer tried to punch Rin',
            },
            { image: gallery16, desc: 'The temple in Udaipur where...' },
            {
              image: gallery17,
              desc: 'The City Palace at Udaipur where we abseiled in!',
            },
            {
              image: gallery18,
              desc: 'The gardens at the Amer Fort which held a secret four hundred years old',
            },
            {
              image: gallery19,
              desc: 'The astronomical structures of Jantar Mantar  where we realised what we were looking for',
            },
            {
              image: gallery20,
              desc: 'Off this street in Down town Jaipur where the man had his lair...',
            },
            {
              image: gallery21,
              desc: 'Some people in Delhi carry a knife on the train in case of trouble.  Others carry a machine gun.',
            },
            { image: gallery22, desc: 'Typical traffic jam in Delhi!' },
            {
              image: gallery23,
              desc: 'The Red Fort in Delhi, my main source of food to feed the family!',
            },
          ]}
        />
      </div>
    </>
  );
};

export default Gallery;
