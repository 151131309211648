import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import { HelmetProvider } from 'react-helmet-async';

import ReactDOM from 'react-dom';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>,
    rootElement
  );
}

// const rootElement = document.getElementById('root');
// const root = hydrateRoot(
//   rootElement,
//   <Provider store={store}>
//     <App />
//   </Provider>
// );
// const hyroot = hydrateRoot(rootElement);
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <Provider store={store}>
//       <App />
//     </Provider>,
//     rootElement
//   );
// } else {
//   render(
//     <Provider store={store}>
//       <App />
//     </Provider>,
//     rootElement
//   );
// }
// root.render();
// if (rootElement.hasChildNodes()) {
//   root.hydrate(
//     <Provider store={store}>
//       <App />
//     </Provider>
//   );
// } else {
//   root.render(
//     <Provider store={store}>
//       <App />
//     </Provider>
//   );
// }
