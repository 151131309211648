import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import BuyNow from '../../BuyNow/BuyNow';
import ReactGA from 'react-ga';

import './bookMobile.css';
import './bookDesktop.css';

import BookPurchase from './BookPurchase/BookPurchase';
import Review from './ReviewFancy/Review';

//Firestore
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase_config';
import { Helmet } from 'react-helmet-async';

const BOOKIDS = {
  rover: 7122505498803,
  kalkin: 7122691555507,
  // aditi: 7122594857139,
};

const Book = () => {
  let { bookname } = useParams();

  // const [bookName, setBookName] = useState(bookName);
  const [bookReviews, setBookReviews] = useState([]);
  const [averageBookStar, setAverageBookStar] = useState(0);

  const book_coll = bookname + '_reviews';
  const bookReviewRef = collection(db, book_coll);

  const getBookReview = async () => {
    let avg = 0;
    let c = 0;
    const queryRoverSnapshot = await getDocs(bookReviewRef);
    let tempBookReviews = [];
    queryRoverSnapshot.forEach((doc) => {
      tempBookReviews = [...tempBookReviews, doc.data()];
      avg += doc.data().rating;
      c += 1;
    });
    avg = avg / c;
    setAverageBookStar(avg);
    setBookReviews(tempBookReviews);
  };

  useEffect(() => {
    getBookReview();
  }, [bookname]);

  useEffect(() => {
    ReactGA.pageview('/book/' + bookname);
  }, []);
  return (
    <>
      <Helmet>
        <title>Delhistreetdog {bookname}</title>
        <meta
          name='description'
          content={
            'Discover the mysteries of ' + bookname + ' the Delhi Street Dog'
          }
        />
        <link rel='canonical' href={'/book/' + bookname} />
      </Helmet>
      {BOOKIDS[bookname] ? (
        <div className='bookPage'>
          <div className='book_wrapper'>
            <div className='book_background'></div>

            <BookPurchase
              bookId={BOOKIDS[bookname]}
              bookName={bookname}
              avgRating={averageBookStar}
            />
          </div>
          <div className='book_review_wrapper'>
            <div className='book_review_container'>
              <h1>{bookReviews != [] ? 'Reviews' : 'No reviews yet'}</h1>
              {/* {console.log(bookReviews)} */}
              {bookReviews.map(({ rating, review, reviewer }) => (
                <Review
                  key={review + Date.now()}
                  name={reviewer}
                  stars={rating}
                  review={review}
                />
              ))}
              ;
              {/* <Review
                name='Loretta Harrison'
                stars={5}
                review="I'm an English woman, and I think of myself as a bit of an
                internationalist. I loved this first book, Rover: the story,
                which bounds along like a dog after a bone, the characters, who
                I really got into, and the picture of India, its rich, its poor,
                its flora and its fauna. Can't wait for Kalkin and Aditi!"
              /> */}
            </div>
          </div>
        </div>
      ) : (
        <Navigate to='/404' replace />
      )}
    </>
  );
};

export default Book;
