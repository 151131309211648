import { reviewActionTypes } from '../constants/reviewActionTypes';

const initialState = {
  pending: [],
  declined: [],
  rover: [],
  kalkin: [],
  aditi: [],
};
export const reviewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case reviewActionTypes.REVIEW_FROM_SERVER:
      return {
        ...state,
        pending: payload.pending,
        declined: payload.declined,
        rover: payload.rover,
        kalkin: payload.kalkin,
        aditi: payload.aditi,
      };
    default:
      // console.log('errro');
      return { ...state };
  }
};
