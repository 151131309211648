import React from 'react';
import './loadinganimated.css';

const LoadingAnimated = () => {
  return (
    <div className='lds-dsd'>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingAnimated;
