import React, { useState } from 'react';
import './header.css';
import Dropdown from './Dropdown/Dropdown';
import { Link } from 'react-router-dom';

// redux
import { useDispatch, connect } from 'react-redux';
import { toggleHeader } from '../../redux/actions/headerActions';
import { userAuth } from '../../redux/actions/userAuthActions';

const Header = (props) => {
  const dispatch = useDispatch();

  // onAuthStateChanged(auth, (currentUser) => {
  //   dispatch(userAuth(currentUser));
  // });

  // const logOutUser = async () => {
  //   try {
  //     await signOut(auth);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const menu = document.querySelector('.menu');

  const closeMenuIfMobile = () => {
    if (!props.RX_header_desktop) {
      dispatch(toggleHeader());
    }
  };

  return (
    <div className='header'>
      <link rel='stylesheet' href='./css/navbar.css' />
      <nav>
        <ul className={props.RX_header_desktop ? 'menu' : 'menu active'}>
          <li className='item'>
            <Link
              onClick={() => {
                closeMenuIfMobile();
              }}
              to='/'
            >
              HOME
            </Link>
          </li>
          <Dropdown title='BOOKS'>
            <Dropdown.Option
              onClick={() => {
                closeMenuIfMobile();
              }}
              title='Rover'
              to='/about/rover'
            />
            <Dropdown.Option
              onClick={() => {
                closeMenuIfMobile();
              }}
              title='Kalkin'
              to='/about/kalkin'
            />
            <Dropdown.Option
              onClick={() => {
                closeMenuIfMobile();
              }}
              title='Aditi'
              to='/about/aditi'
            />
          </Dropdown>
          <li className='logo'>
            <a>
              <img src={props.logo} alt='delhistreetdogs' />
            </a>
          </li>
          <li className='item'>
            <Link
              onClick={() => {
                closeMenuIfMobile();
              }}
              to='/reviews'
            >
              REVIEWS
            </Link>
          </li>
          <li className='item'>
            <Link
              onClick={() => {
                closeMenuIfMobile();
              }}
              to='/gallery'
            >
              GALLERY
            </Link>
          </li>
          <li className='toggle'>
            <a>
              <i
                onClick={() => {
                  dispatch(toggleHeader());
                }}
                className={
                  props.RX_header_desktop ? 'icon-reorder' : 'icon-remove'
                }
              ></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    RX_header_desktop: state.toggleHeader.header_desktop,
    RX_user: state.setUser.user,
  };
}
export default connect(mapStateToProps)(Header);
