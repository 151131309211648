import React, { useState } from 'react';
import './login.css';

import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../../firebase_config';

//Redux
import { useDispatch } from 'react-redux';
import { userAuth } from '../../../../redux/actions/userAuthActions';

const Login = () => {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const dispatch = useDispatch();
  onAuthStateChanged(auth, (currentUser) => {
    dispatch(userAuth(currentUser));
  });

  const loginUser = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      ).catch((error) => {
        if (!error.message.includes('(auth/invalid-email)')) {
          console.log(error.message);
        }
      });
      console.log(user);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className='login_wrapper'>
      <div className='login_container'>
        <div className='form'>
          <input
            type='email'
            name='email'
            className='primary-input'
            placeholder='Email'
            onChange={(event) => {
              setLoginEmail(event.target.value);
            }}
          />
          <input
            type='password'
            name='password'
            className='primary-input'
            placeholder='Password'
            onChange={(event) => {
              setLoginPassword(event.target.value);
            }}
          />
          <button onClick={() => loginUser()} className='primary-button'>
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
