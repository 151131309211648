import React from 'react';
import Client from '@shopify/buy-button-js';
import { useState } from 'react';
import { useEffect } from 'react';
import LoadingAnimated from '../../../../LoadingAnimated/LoadingAnimated';

const BuyButton = ({ product }) => {
  const [client, setClient] = useState();
  const [buttonRendered, setButtonRendered] = useState(false);

  const fetchClient = () => {
    try {
      if (client == undefined) {
        var clientTemp = Client.buildClient({
          domain: process.env.REACT_APP_PUBLIC_MY_API_URL,
          storefrontAccessToken: process.env.REACT_APP_MY_SECRET_API_TOKEN, // previously apiKey, now deprecated
        });
        setClient(clientTemp);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  function placeButton() {
    if (client && product.id && !buttonRendered) {
      var ui = Client.UI.init(client);
      ui.createComponent('product', {
        id: product.id,
        node: document.getElementById('buyButtonTarget'),
        moneyFormat: '%C2%A3%7B%7Bamount%7D%7D',
        options: {
          product: {
            iframe: false,
            styles: {
              product: {
                '@media (min-width: 601px)': {
                  'max-width': 'calc(25% - 20px)',
                  'margin-left': '20px',
                  'margin-bottom': '50px',
                },
                'text-align': 'left',
              },
              quantityInput: {
                'font-size': '18px',
                'padding-top': '17px',
                'padding-bottom': '17px',
              },
            },
            contents: {
              img: false,
              title: false,
              price: false,
            },
            text: {
              button: 'Add To Basket',
            },
            googleFonts: ['Roboto'],
          },
          productSet: {
            styles: {
              products: {
                '@media (min-width: 601px)': {
                  'margin-left': '-20px',
                },
              },
            },
          },
          modalProduct: {
            contents: {
              img: false,
              imgWithCarousel: true,
              button: false,
              buttonWithQuantity: true,
            },
            styles: {
              product: {
                '@media (min-width: 601px)': {
                  'max-width': '100%',
                  'margin-left': '0px',
                  'margin-bottom': '0px',
                },
              },
              button: {
                'font-family': 'Roboto, sans-serif',
                'font-size': '18px',
                'padding-top': '17px',
                'padding-bottom': '17px',
                ':hover': {
                  'background-color': '#bd2d59',
                },
                'background-color': '#d23263',
                ':focus': {
                  'background-color': '#bd2d59',
                },
                'border-radius': '0px',
                'padding-left': '27px',
                'padding-right': '27px',
              },
              quantityInput: {
                'font-size': '18px',
                'padding-top': '17px',
                'padding-bottom': '17px',
              },
            },
            googleFonts: ['Roboto'],
            text: {
              button: 'Add to cart',
            },
          },
          option: {},
          cart: {
            styles: {
              button: {
                'font-family': 'Roboto, sans-serif',
                'font-size': '18px',
                'padding-top': '17px',
                'padding-bottom': '17px',
                ':hover': {
                  'background-color': '#bd2d59',
                },
                'background-color': '#d23263',
                ':focus': {
                  'background-color': '#bd2d59',
                },
                'border-radius': '0px',
              },
              title: {
                color: '#ffffff',
              },
              header: {
                color: '#ffffff',
              },
              lineItems: {
                color: '#ffffff',
              },
              subtotalText: {
                color: '#ffffff',
              },
              subtotal: {
                color: '#ffffff',
              },
              notice: {
                color: '#ffffff',
              },
              currency: {
                color: '#ffffff',
              },
              close: {
                color: '#ffffff',
                ':hover': {
                  color: '#ffffff',
                },
              },
              empty: {
                color: '#ffffff',
              },
              noteDescription: {
                color: '#ffffff',
              },
              discountText: {
                color: '#ffffff',
              },
              discountIcon: {
                fill: '#ffffff',
              },
              discountAmount: {
                color: '#ffffff',
              },
              cart: {
                'background-color': '#1c1a1e',
              },
              footer: {
                'background-color': '#1c1a1e',
              },
            },
            text: {
              total: 'Subtotal',
              button: 'Checkout',
            },
            googleFonts: ['Roboto'],
          },
          toggle: {
            styles: {
              toggle: {
                'font-family': 'Roboto, sans-serif',
                'background-color': '#d23263',
                ':hover': {
                  'background-color': '#bd2d59',
                },
                ':focus': {
                  'background-color': '#bd2d59',
                },
              },
              count: {
                'font-size': '18px',
              },
            },
            googleFonts: ['Roboto'],
          },
          lineItem: {
            styles: {
              variantTitle: {
                color: '#ffffff',
              },
              title: {
                color: '#ffffff',
              },
              price: {
                color: '#ffffff',
              },
              fullPrice: {
                color: '#ffffff',
              },
              discount: {
                color: '#ffffff',
              },
              discountIcon: {
                fill: '#ffffff',
              },
              quantity: {
                color: '#ffffff',
              },
              quantityIncrement: {
                color: '#ffffff',
                'border-color': '#ffffff',
              },
              quantityDecrement: {
                color: '#ffffff',
                'border-color': '#ffffff',
              },
              quantityInput: {
                color: '#ffffff',
                'border-color': '#ffffff',
              },
            },
          },
        },
      });
      setButtonRendered(true);
    }
  }

  useEffect(() => {
    fetchClient();
    placeButton();
  }, [client, product]);

  return (
    <div id='buyButtonTarget'>{!buttonRendered && <LoadingAnimated />}</div>
  );
};

export default BuyButton;
