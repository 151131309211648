import React from 'react';

import ksd from '../../../assets/images/friends/kolkata_street_dogs.jpeg';

import Styles from './friends.module.css';

const Friends = () => {
  return (
    <div className={Styles.Friends}>
      <div className={Styles.Entries}>
        <div className={Styles.Entry}>
          <h1>KOLKATA STREET DOGS</h1>
          <img alt='kolkata street dogs' src={ksd} />
          <p>
            "We're a vibrant group of people from Kolkata, elsewhere in India
            and around the world who simply like street dogs. Some have adopted
            street dogs as pets in their own home, some feed and care for dogs
            in their own locality, and others are just here out of curiosity."
          </p>
          <div className={Styles.Links}>
            <a href='https://www.facebook.com/groups/1382277738753927/'>
              Click for their facebook!
            </a>
            <a href='https://kolkatastreetdogsdotin.wordpress.com/'>
              Click for their website!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Friends;
